import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { LoginService } from '../../../services/login/login.service';
import { ToastrService } from 'ngx-toastr';
import { CommomDataService } from '../../../services/shareData/commom-data.service';
import { MustMatchValidator } from '../../validators/must-match.validator';
import { FeildValidatorService } from 'src/app/shared/validators/feild-validator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ValidationService } from '../../../shared/services/validation.service';
import { PagesService } from '../../../services/pages/pages.service';
import { DomSanitizer } from '@angular/platform-browser';
import { staticData } from '../../../models/static_model';
import { EncryptionService } from '../../../shared/services/encryption.service';
import { HttpErrorHandlingService } from '../../../shared/services/http-error-handling.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  showForm: any = {
    login: false,
    signup: false,
    otp: false,
    setPassword: false,
    forgetPassword: false,
    showCounter: false,
    disableResendOTP: true,
    showclose: true
  };
  loginForm: FormGroup;
  forgetPassForm: FormGroup;
  signupForm: FormGroup;
  otpForm: FormGroup;
  setPassForm: FormGroup;
  timerCount = 60;
  userInfo: any = {};
  loading: boolean = false;
  regex: any = {
    email: /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i,
    name: /^[a-zA-Z]{3,25}$/,
    phone: /^[0][0-9]{9}$|^[7][0-9]{8}$/,
    nameWS: /^[a-zA-Z][a-zA-Z ]+$/,
    password: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,32}$/
  };
  
  showPassText: any = [];
  isformSubmitted = false;
  items: FormArray;
  rememberMe: any = {
    'email': '',
    'pass': ''
  };
  error_messages: any;
  isOtpExpired: boolean = false;
  timer: any;
  termsData: any = {};
  authData: any = {};
  staticDatas = staticData;

  // user flow constants.
  LOGIN_FLOW:number = 1;
  FORGOT_PASSWORD_FLOW:number = 2;
  VERIFY_OTP_FLOW:number = 3;
  REGISTER_FLOW:number = 4;

  currentFlow = null;
  @Input() formData = null;
  showLoginDiv:boolean = true;  

  constructor(private formBuilder: FormBuilder, private loginService: LoginService,
    private toastr: ToastrService, private dataService: CommomDataService, private domSanitize: DomSanitizer,
    private fieldValidator: FeildValidatorService, private router: Router, private pagesSer: PagesService,
    private activatedRoute: ActivatedRoute, private encyService: EncryptionService,
    private errorService: HttpErrorHandlingService, private location: Location) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.error_messages = this.fieldValidator.getValidation();
    //set when the user in verification flow for the registration.
    if(this.currentFlow == this.VERIFY_OTP_FLOW){
      this.showLoginDiv = false;
      this.loading = true;
      this.loginService.verifyEmail({ slug : this.formData.token }).subscribe((v:any)=>{
        this.showLoginDiv = true;
        if(v.success){
          this.toastr.success(v.message);
          this.setUpVerifyFlow(v);
          this.activateOtp();
        }else{
          if(v.message != null || v.message != ""){
            this.toastr.error(v.message);
          }else{
            this.toastr.error('Something went wrong!');
          }
          window.setTimeout(()=>{
            this.router.navigate(['/']);
          },3000);
          this.activateLogin();
        }
        this.loading = false;
      });
    
    } else{
      // old flow 
      this.dataService.getResetPass().subscribe((data: any) => {
        if (data.hasOwnProperty('type')) {
          this.authData = data;
          if (data.type == 1) {
            this.activateLogin();
          } else {
            this.activateSetPassword();
          }
        } else {
          //default login flow.
          this.currentFlow = this.LOGIN_FLOW;
          this.activateLogin();
        }
      });
    }
    this.getTermsData();
    this.checkRememberMe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.formData.currentValue.type == 3){
      this.setCurrentFlow(this.VERIFY_OTP_FLOW);
    }
    
  }

  setUpVerifyFlow(v:any){
    this.location.replaceState('/');
    this.userInfo.email = v.data.email;
  }

  getTermsData() {
    this.pagesSer.getPages('terms-conditions').subscribe((res: any) => {
      console.log(res);
      if (res.success) {
        this.termsData = res.data.records[0];
        this.termsData.long_description = this.domSanitize.bypassSecurityTrustHtml(this.termsData.long_description);
      }
    }, (error) => {
      this.errorService.handleError(error);
      console.log(error);
    })
  }

  activateSignUpForm() {
    this.isformSubmitted = false;
    this.showForm.login = false;
    this.showForm.signup = true;
    this.showForm.showclose = true;
    this.setSignupForm();
  }
  activateforgetPassword() {
    this.isformSubmitted = false;
    this.showForm.login = false;
    this.showForm.signup = false;
    this.showForm.forgetPassword = true;
    this.showForm.showclose = true;
    this.setForgetPassForm();
  }
  activateLogin() {
    this.isformSubmitted = false;
    this.showForm.login = true;
    this.showForm.setPassword = false;
    this.showForm.signup = false;
    this.showForm.forgetPassword = false;
    this.showForm.showclose = true;
    this.setLoginForm();
    this.showPassText[1] = 'Show';
  }

  activateOtp() {
    this.isformSubmitted = false;
    this.showForm.login = false;
    this.showForm.otp = true;
    this.showForm.signup = false;
    this.showForm.showCounter = true;
    this.showForm.showclose = false;
    this.setOtpForm();
    this.executeTimer();
  }

  activateSetPassword() {
    this.isformSubmitted = false;
    this.showForm.setPassword = true;
    this.showForm.otp = false;
    this.showForm.showclose = false;
    this.setPasswordForm();
    this.showPassText[2] = 'Show';
    this.showPassText[3] = 'Show';
  }

  setLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: [this.rememberMe.email, Validators.compose([Validators.required, ValidationService.validateEmail, Validators.minLength(3), Validators.maxLength(50)])],
      password: [this.rememberMe.pass, Validators.compose([Validators.required, , Validators.maxLength(50)])],
      rememberMe: [false],
      tnc: [true]
    });
  }

  setSignupForm() {
    this.signupForm = this.formBuilder.group({
      userType: ['Individual', Validators.required],
      firstName: ['', Validators.compose([Validators.required, ValidationService.onlyAlphabetsWithDot, Validators.minLength(3), Validators.maxLength(50)])],
      middleName: ['', Validators.compose([])],
      lastName: ['', Validators.compose([Validators.required, ValidationService.onlyAlphabetsWithDot, Validators.minLength(3), Validators.maxLength(50)])],
      phone: ['', Validators.compose([Validators.required, ValidationService.validatePhone])],
      email: ['', Validators.compose([Validators.required, ValidationService.validateEmail, Validators.minLength(3), Validators.maxLength(50), ValidationService.domainEmailValidator])],
      items: this.formBuilder.array([]),
      tnc: [false, Validators.compose([Validators.requiredTrue])]
    });
  }

  getArrayControls() {
    return (this.signupForm.get('items') as FormArray).controls;
  }

  setBusinessField() {
    this.items = this.signupForm.get('items') as FormArray;
    if (this.signupForm.value.userType == 'Individual') {
      this.items.removeAt(0);
    } else {
      this.items.push(this.formBuilder.group({
        business_name: ['', Validators.compose([Validators.required, ValidationService.onlyAlphaChars, Validators.minLength(3), Validators.maxLength(50)])]
      })
      );
    }
  }

  setOtpForm() {
    this.otpForm = this.formBuilder.group({
      field1: ['', Validators.required],
      field2: ['', Validators.required],
      field3: ['', Validators.required],
      field4: ['', Validators.required],
      field5: ['', Validators.required],
      field6: ['', Validators.required]
    });
  }

  setPasswordForm() {
    this.setPassForm = this.formBuilder.group({
      password: ['', Validators.compose([Validators.required, ValidationService.passwordValidator2])],
      cPassword: ['', Validators.compose([Validators.required])]
    },
      {
        validators: ValidationService.MustMatch("password", "cPassword")
      })
  }

  setForgetPassForm() {
    this.forgetPassForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, ValidationService.validateEmail, Validators.minLength(3), Validators.maxLength(50)])]
    });
  }

  login() {
    console.log(this.loginForm);
    this.isformSubmitted = true;
    if (this.loginForm.valid) {
      this.loading = true;
      const dataToSend = {
        "email": this.loginForm.value.email.toLowerCase(),
        "password": this.loginForm.value.password
      }

      
      this.loginService.login(dataToSend).subscribe((res: any) => {
        console.log(res);
        if (res.success) {
          this.setCurrentFlow(this.LOGIN_FLOW);
          this.loading = false;
          this.showForm.login = false;
          this.activateOtp();
          this.userInfo.email = this.loginForm.get('email').value;
          let phone = res.data && res.data.records ? res.data.records.phone :'';
          console.log(res.data.records,"res.data.recordsres.data.records")
          if(phone){
            this.userInfo.phone=phone;
          }
          console.log(this.userInfo,res,"this.userInfothis.userInfo")
          return;
          
          // let data = res.data.records;
          // let rememberMe = {};
          // window.localStorage.setItem('token', data.token);
          // //When not logged in user tries to buy policy this event will emit which helps in redirect
          // this.dataService.quoteSelected();
          // if (this.loginForm.value.rememberMe) {
          //   rememberMe['email'] = this.loginForm.value.email;
          //   rememberMe['password'] = this.loginForm.value.password;
          //   window.localStorage.setItem('rememberMe', this.encyService.encryption(rememberMe));
          // } else {
          //   window.localStorage.setItem('rememberMe', '');
          // }
          // window.localStorage.setItem('customer_data', this.encyService.encryption(data));
          // this.dataService.setUserInfo({ 'name': data.full_name, 'image': data.image });
          // this.dataService.setUserLogin({ isLogin: true, customer_type: data.customer_type });
          // this.closeForm();
          // this.toastr.success(res.message, 'Success');
          // this.redirection();
        } else {
          this.toastr.error(res.message, 'Error');
        }
        this.loading = false;
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.errorService.handleError(error);
          // this.toastr.error('error', 'Error');
        });
    } else {
      let error = this.loginForm.controls;
      let keys = Object.keys(error);
      this.showToast(error, keys);
    }
  }

  signup() {
    console.log(this.signupForm);
    this.isformSubmitted = true;
    if (this.signupForm.valid) {
      this.loading = true;
      this.userInfo = this.signupForm.value;
      const fullName = this.userInfo.firstName + ' ' + this.userInfo.middleName + ' ' + this.userInfo.lastName;
      let phone = this.userInfo.phone ? this.userInfo.phone.replace(/^0+/, '') :'';
      phone = phone + '';
      // if (phone.charAt(0) === '0') {
      //   phone = phone.slice(1);
      // }
      // phone = `${this.staticDatas.phonecode}${phone}`;
      const dataToSend = {
        "full_name": fullName,
        "email": this.userInfo.email.toLowerCase(),
        "phone": phone,
        "customer_type": this.userInfo.userType,
        "first_name": this.userInfo.firstName,
        "middle_name": this.userInfo.middleName,
        "last_name": this.userInfo.lastName,
        "country_code": `${this.staticDatas.phonecode}`
      }
      if (this.userInfo.userType == 'Business') {
        dataToSend['business_name'] = this.userInfo.items[0].business_name;
      }
      this.loginService.signup(dataToSend).subscribe((res: any) => {
        console.log(res);
        if (res.success) {
          this.toastr.success(res.message, 'Success');
          this.activateLogin();
        } else {
          this.toastr.error(res.message, 'Error');
        }
        this.loading = false;
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.errorService.handleError(error);
        });
    } else {
      let error = this.signupForm.controls;
      let keys = Object.keys(error);
      this.showToast(error, keys);
    }
  }

  fogetPassword() {
    console.log(this.forgetPassForm);
    this.isformSubmitted = true;
    if (this.forgetPassForm.valid) {
      this.loading = true;
      const dataToSend = {
        "email": this.forgetPassForm.value.email.toLowerCase()
      }
      this.loginService.forgetPassword(dataToSend).subscribe((res: any) => {
        console.log(res);
        if (res.success) {
          this.toastr.success(res.message, 'Success');
          this.activateLogin();
        } else {
          this.toastr.error(res.message, 'Error');
        }
        this.loading = false;
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.errorService.handleError(error);
        });
    } else {
      this.toastr.error("Please enter all mandatory fields!", 'Error');
    }
  }

  submitOTP() {
    this.isformSubmitted = true;
    if (this.otpForm.valid) {
      this.loading = true;
      if (this.isOtpExpired == true) {
        this.toastr.error("OTP expired, Please resend it.", 'Error');
        this.loading = false;
        return false;
      }
      const otpValues = this.otpForm.value;
      const finalOtp = Object.keys(otpValues).reduce((con, key) => {
        con = con + '' + otpValues[key];
        return con;
      }, '');
      const dataToSend = {
        "email": this.userInfo.email.toLowerCase(),
        "otp": finalOtp,
        "flow" : this.currentFlow == this.LOGIN_FLOW ? 'login' : (this.currentFlow == this.VERIFY_OTP_FLOW ? 'verify' : 'register')
      }

      this.loginService.verifyOTP(dataToSend).subscribe((res: any) => {
        if (res.success) {
          // this.toastr.success(res.message, 'Success');
          if(this.currentFlow == this.LOGIN_FLOW || this.currentFlow == this.VERIFY_OTP_FLOW || this.currentFlow == this.FORGOT_PASSWORD_FLOW){
            this.executeUserFlow(res.data.records);
            clearTimeout(this.timer);
            this.loginService.setUserLoggedIn(true);
          }else{
           this.redirectIndex();
          }
        } else {
          this.toastr.error(res.message, 'Error');
        }
        this.loading = false;
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.errorService.handleError(error);
        });
    } else {
      this.toastr.warning('Please fill valid OTP', 'Error');
    }
  }

  setFocus(pre: any, curr: any, next: any) {
    if (curr.value.length > 1) {
      let value = curr.value + '';
      curr.value = value.slice(value.length - 1, value.length);
    }
    if (curr.value && next != 'l') {
      next.focus();
    } else if (!curr.value && pre != 'f') {
      pre.focus();
    }
  }

  setPassword() {
    this.isformSubmitted = true;
    console.log(this.setPassForm);
    if (this.setPassForm.valid && this.setPassForm.value.password == this.setPassForm.value.cPassword) {
      this.loading = true;
      if (this.authData.hasOwnProperty('type')) {
        let formData = {
          "confirmPassword": this.setPassForm.value.password,
          "password": this.setPassForm.value.cPassword
        }
        this.loginService.UpdatePassword(formData, this.authData.token).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success(res.message, "Success");
            this.authData = {};
            // this.dataService.setResetPass({});
            this.router.navigate(['/login']);
            // this.activateLogin();
          } else {
            this.toastr.error(res.message, "Error");
          }
          this.loading = false;
        }, (error) => {
          console.log(error);
          this.loading = false;
          this.errorService.handleError(error);
        });
      } else {
        let dataToSend = {
          "email": this.userInfo.email.toLowerCase(),
          "confirmPassword": this.setPassForm.value.password,
          "password": this.setPassForm.value.cPassword
        }
        this.loginService.setPassword(dataToSend).subscribe((res: any) => {
          console.log(res);
          if (res.success) {
            this.toastr.success(res.message, 'Success');
            this.closeForm();
            this.closeOtpForm();
            this.activateLogin();
          } else {
            this.toastr.error(res.message, 'Error');
          }
          this.loading = false;
        },
          (error) => {
            console.log(error);
            this.loading = false;
            this.errorService.handleError(error);
          });
      }
    } else {
      this.toastr.warning('Please enter all mandatory fields!', 'Error');
    }
  }

  executeTimer() {
    this.timerCount = 60;
    let timer = setInterval(() => {
      this.timerCount--;
      if (this.timerCount == 0) {
        this.showForm.disableResendOTP = false;
        this.showForm.showCounter = false;
        clearInterval(timer);
      }
    }, 1000);
  }

  resendOTP() {
    if (this.timerCount == 0) {
      this.loading = true;
      const dataToSend = {
        "email": this.userInfo.email.toLowerCase(),
        "flow" : this.currentFlow == this.LOGIN_FLOW ? 'login' : (this.currentFlow == this.VERIFY_OTP_FLOW ? 'verify' : 'register')
      }
      this.loginService.resendOTP(dataToSend).subscribe((res: any) => {
        console.log(res);
        if (res.success) {
          clearTimeout(this.timer);
          this.showForm.showCounter = true;
          this.executeTimer();
          this.isOtpExpired = false;
          this.execSetTimeOut('300000');
          this.toastr.success(res.message, 'Success');
        } else {
          this.toastr.error(res.message, 'Error');
        }
        this.loading = false;
      },
        (error) => {
          console.log(error);
          this.loading = false;
          this.errorService.handleError(error);
        });
    } else {
      this.toastr.warning(`Resend OTP after ${this.timerCount} Second`, 'Error');
    }
  }

  closeForm() {
    
    this.dataService.setLoginPopup(false);
  }


  closeOtpForm(){
    this.dataService.setOtpPopup(false);
  }

  showPassword(input: any, index) {
    input.type = input.type == 'password' ? 'text' : 'password';
    this.showPassText[index] = input.type == 'password' ? 'Show' : 'Hide';
  }

  checkRememberMe() {
    if (window.localStorage.getItem('rememberMe')) {
      let data = JSON.parse(this.encyService.decryption(window.localStorage.getItem('rememberMe')));
      this.rememberMe.email = data.email;
      this.rememberMe.pass = data.password;
    }
  }

  showToast(error, keys) {
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]].errors != null && keys[i] != 'items') {
        if (keys[i] != 'tnc') {
          this.toastr.error('Please enter all mandatory fields!', 'Error');
          break;
        }
      } else if (keys[i] == 'items') {
        let field = this.getArrayControls();
        console.log(field);
        if (field.length > 0) {
          let items = (<FormArray>this.signupForm.get('items')).at(0).status;
          if (items == "INVALID") {
            this.toastr.error('Please enter all mandatory fields!', 'Error');
            break;
          }
        }
      }
      if (error['tnc'].errors != null && keys.length - 1 == i) {
        this.toastr.warning('Please select term & conditions', 'Error');
        break;
      }
    }
  }

  redirection() {
    let state = this.router.url.split('/');
    if (state[1] != 'product') {
      this.router.navigate(['/']);
    }
  }

  redirectIndex(){
    this.router.navigate(['/']);
  }

  execSetTimeOut(time) {
    this.timer = setTimeout(() => {
      this.isOtpExpired = true;
    }, time);
  }

  setCurrentFlow(flow:number){
    this.currentFlow = flow;
  }

  executeUserFlow(data){
    if(this.currentFlow == this.LOGIN_FLOW){
      let rememberMe = {};
      this.setLoginFlow(data);
      if (this.loginForm && this.loginForm.value.rememberMe) {
        rememberMe['email'] = this.loginForm.value.email;
        rememberMe['password'] = this.loginForm.value.password;
        window.localStorage.setItem('rememberMe', this.encyService.encryption(rememberMe));
      } else {
        window.localStorage.setItem('rememberMe', '');
      }
      return;
    }
    
    if(this.currentFlow == this.REGISTER_FLOW){
      this.activateSetPassword();
      return;
    }

    if(this.currentFlow == this.VERIFY_OTP_FLOW){
      this.activateSetPassword();
      return;
    }

    if(this.currentFlow == this.FORGOT_PASSWORD_FLOW){
      this.activateSetPassword();
      return;
    }
    // }else{
    //   window.localStorage.setItem('token', data.token);
    //   window.localStorage.setItem('customer_data', this.encyService.encryption(data));
    //   this.dataService.setUserInfo({ 'name': data.full_name, 'image': data.image });
    //   this.dataService.setUserLogin({ isLogin: true, customer_type: data.customer_type });
    //   this.toastr.success(data.message, 'Success');
    //   this.activateSetPassword();
    // }
  }

  setLoginFlow(data:any){
    window.localStorage.setItem('token', data.token);
    // //When not logged in user tries to buy policy this event will emit which helps in redirect
    this.dataService.quoteSelected();
    window.localStorage.setItem('customer_data', this.encyService.encryption(data));
    this.dataService.setUserInfo({ 'name': data.full_name, 'image': data.image });
    this.dataService.setUserLogin({ isLogin: true, customer_type: data.customer_type });
    this.closeForm();
    this.toastr.success('You have successfully login!!', 'Success');
    // this.toastr.success(data.message, 'Success');
    this.redirection();
  }

}
